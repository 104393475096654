import type { Role } from '~/types'

export const roles: Role[] = [
   { title: 'National Admin', value: 1 },
   { title: 'Content Committee', value: 2 },
   { title: 'Training Director', value: 3 },
   { title: 'Instructor', value: 4 },
]

export const formRules = {
   required: [(v: string) => !!v || 'This field is required'],
   name: [(v: string) => !!v || 'Name is required'],
   displayName: [(v: string) => !!v || 'Display Name is required'],
   email: [(v: string) => !!v || 'This is required.', (v: string) => (v && v.length >= 8) || 'Minimum 8 characters.', (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid.'],
   password: [(v: string) => !!v || 'This is required.', (v: string) => (v && v.length >= 8) || 'Minimum 8 characters.'],
   image: [(v: any) => !v || !v.length || v[0].size < 2000000 || 'Image size should be less than 2 MB!'],
   file: [(v: any) => !v || !v.length || v[0].size < 200000000 || 'File size should be less than 200 MB!'],
}
 
export const questionTypes = [
   {
      id: 1,
      title: 'True/False',
      type: 'type'
   },
   {
      id: 2,
      title: 'Multiple Choice',
      type: 'type'
   },
   {
      id: 3,
      title: 'Multiple Response',
      type: 'type'
   },
   // {
   //    id: 4,
   //    title: 'Completion',
   //    type: 'type'
   // },
   // {
   //    id: 5,
   //    title: 'Match',
   //    type: 'type'
   // },
]
export const viewTypes = [
   {
      id: 0,
      title: 'Unviewed',
      type: 'type'
   },
   {
      id: 1,
      title: 'Viewed',
      type: 'type'
   },
]

export const orderedListTypes = ["1", "a", "A", "i", "I"]

export const itemsPerPageOptions = [
   { value: 5, title: '5' },
   { value: 10, title: '10' },
   { value: 25, title: '25' },
   { value: 50, title: '50' },
   { value: 100, title: '100' },
]

export const responsesTF = [
   {
      text: 'True',
      sort_order: 0,
      fraction: 0,
   },
   {
      text: 'False',
      sort_order: 1,
      fraction: 0,
   },
]

export const responsesMC = [
   {
      text: '',
      sort_order: 0,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 1,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 2,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 3,
      fraction: 0,
   },
]
export const responsesMR = [
   {
      text: '',
      sort_order: 0,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 1,
      fraction: 0,
   },
   {
      text: '3',
      sort_order: 2,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 3,
      fraction: 0,
   },
]
export const responsesM = [
   {
      text: '',
      sort_order: 0,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 1,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 2,
      fraction: 0,
   },
   {
      text: '',
      sort_order: 3,
      fraction: 0,
   },
]
export const responseC = [
   {
      text: '',
      sort_order: 0,
      fraction: 0,
   },
]

export const FONT_SIZE_MENU_OPTIONS = [
   "8px",
   "9px",
   "10px",
   "11px",
   "12px",
   "14px",
   "16px",
   "18px",
   "24px",
   "30px",
   "36px",
   "48px",
   "60px",
   "72px",
   "96px",
 ]
