import type { ThemeDefinition } from "vuetify"

export const iecLightTheme: ThemeDefinition = {
   dark: false,
   colors: {
      primary: '#004280',
      secondary: '#335c83',
      'brand-primary': '#144f92',
      'brand-secondary': '#7183b5',
      accent: '#cccccc',
      error: '#B00020',
      info: '#20e3fd',
      success: '#4CAF50',
      warning: '#FB8C00',
      'brand-grey': '#666666',
      'brand-blue-grey': '#607D8B',
      'brand-blue-grey-lighten-1': '#607D8B',
      'brand-blue-grey-lighten-2': '#607D8B',
      'brand-blue-grey-lighten-3': '#B0BEC5',
      'brand-blue-grey-lighten-4': '#CFD8DC',
      'brand-blue-grey-lighten-5': '#ECEFF1',
   }
}
