<script setup>
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/stores/app'

const appStore = useAppStore()
const { isMobile } = storeToRefs(appStore)

const props = defineProps({
  error: Object,
})
const pageNotFound = '404 - Page Not Found'
const otherError = 'An error occurred'

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <v-container fluid fill-height :class="isMobile ? 'pa-0' : ''">
    <v-card elevation="6" max-width="640px" class="mx-auto overflow-auto" :class="isMobile ? 'rounded-0' : ''">
      <v-card-text>
        <v-container :class="isMobile ? 'pa-0' : ''">
          <v-row>
            <v-col cols="12" class="bg-primary d-flex flex-column align-center">
              <v-col cols="5" sm="4" md="3" class="mt-2 bg-white">
                <GlobalAppLogo :fullcolor="true" />
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="my-3 text-center text-deep-orange-darken-2">
              <h1 v-if="error.statusCode === 404">
                {{ pageNotFound }}
              </h1>
              <h1 v-else>{{ otherError }}</h1>
            </v-col>
            <v-col cols="12" class="">
              <v-btn x-large block color="primary" @click="handleError"> Home </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
