import revive_payload_client_4sVQNw7RlN from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AUP22rrBAc from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import html2canvas_client_5Ax7lu20g2 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/html2canvas.client.ts";
import html2pdf_client_CnElePg9y5 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/html2pdf.client.ts";
import piniaPlugin_2d7eRqwsc8 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/piniaPlugin.ts";
import vuetify_7h9QAQEssH from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AUP22rrBAc,
  html2canvas_client_5Ax7lu20g2,
  html2pdf_client_CnElePg9y5,
  piniaPlugin_2d7eRqwsc8,
  vuetify_7h9QAQEssH
]