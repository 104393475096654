import { storeToRefs } from 'pinia'
import usersJSON from '~/data/users.json'
import type { ErrorT, User, Role } from '~/types'
import { roles } from '~/constants/app'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useFilterStore } from '~/stores/filter'
import { useAPI } from '~~/composables/useAPI'
import { useNotificationStore } from '~~/stores/notification'

export const useUserStore = defineStore('userStore', () => {
   const { setSnackbar } = useSnackbarStore()
   const { headers, isAuthenticated } = storeToRefs(useAuthStore())
   const { chapterFilters, roleFilters } = storeToRefs(useFilterStore())
   const config = useRuntimeConfig()
   const { showError } = useAPI()
   const { unreadNotifications } = storeToRefs(useNotificationStore())
   const users = ref<User[]>([])
   const user = ref<User>()
   const loginUsers = ref<User[]>([])
   const tempUser = ref<User>()
   const newUser = ref<User>(<User>{} as User)
   const title = ref('')
   const isValid = ref(true)
   const isPending = ref(false)
   const error = ref(null)
   const total = ref(0)
   const cacheBuster = ref(0)

   const selectedChapterFilters = ref([])
   const selectedRoleFilters = ref([])

   const parameters = ref({
      page: 1,
      per_page: 5,
      order_direction: '',
      groupBy: [],
      order_by: 'lastName',
      search: '',
      watch: [cacheBuster.value]
   })
   const params = computed(() => {
      return { ...parameters.value }
   })
   const paramsCR = computed(() => {
      return { ...parameters.value, ...selectedChapterFilters.value, ...selectedRoleFilters.value }
   })

   const unreadUserNotifications = computed(() => {
      return unreadNotifications.value
   })


   const getUserById = computed(() => {
      // console.log(`getUserById()`)
      return (userId: number) => users.value.find((user) => user.id === userId)
   })
   const fullName = computed(() => user.value ? `${user.value.firstName} ${user.value.lastName}` : '')
   const role = computed(() => user.value ? user.value.role_id : 0)
   const isAdmin = computed<boolean>(() => {
      return role.value === 1 ? true : false
   })
   const isCommittee = computed<boolean>(() => {
      return role.value === 2 ? true : false
   })
   const isDirector = computed<boolean>(() => {
      return role.value === 3 ? true : false
   })
   const isInstructor = computed<boolean>(() => {
      return role.value >= 4 ? true : false
   })

   async function fetchLoginUsers() {
      // console.log(`fetchLoginUsers()`)
      isPending.value = true
      loginUsers.value = usersJSON
      isPending.value = false
   }

   const { data: usersData, error: usersError, execute: execUsers } = useFetch(`${config.public.API_URL}/api/user/list`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsCR,
      immediate: false,
      watch: false,
   })

   async function fetchUsers() {
      console.log(`fetchUsers()`)
      isPending.value = true

      selectedChapterFilters.value = []
      selectedRoleFilters.value = []

      if (chapterFilters.value.length) {
         chapterFilters.value.forEach((chapter: number[], index: number) => {
            selectedChapterFilters.value[`selected_chapters[${index}]`] = chapter
         })
      }
      if (roleFilters.value.length) {
         roleFilters.value.forEach((role: number[], index: number) => {
            selectedRoleFilters.value[`selected_roles[${index}]`] = role
         })
      }

      await execUsers()

      if (usersError.value) {
         showError(usersError.value as ErrorT)
      } else if (usersData.value) {
         // console.log(`  users = ${JSON.stringify(usersData.value.data)}`)
         total.value = usersData.value.total
         users.value = usersData.value.data
      }
      isPending.value = false
   }


   const { data: userData, error: userError, execute: execUser } = useFetch(`${config.public.API_URL}/api/user/userinfo`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })

   async function fetchUser() {
      console.log(`fetchUser()`)
      cacheBuster.value += 1
      isPending.value = true

      await execUser()

      if (userError.value) {
         showError(userError.value as ErrorT)
         isAuthenticated.value = false
         console.log(`  isAuthenticated = ${isAuthenticated.value}`)
         isPending.value = false
         return ''
      } else if (userData.value) {
         console.log(`  user = ${JSON.stringify(userData.value)}`)
         user.value = { ...userData.value.message }
         isAuthenticated.value = true
         isPending.value = false
         return userData.value
      }
   }

   const userID = ref(0)
   const userURL = computed(() => `${config.public.API_URL}/api/users/${userID.value ? userID.value : 0}`)

   const { data: userIDData, error: userIDError, execute: execUserID } = useFetch(() => { return userURL.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })

   async function fetchUserById(id: number) {
      // if (isPending.value) return

      console.log(`\n fetchUserById(${id})`)
      isPending.value = true
      userID.value = id

      await execUserID()

      if (userIDError.value) {
         showError(userIDError.value as ErrorT)
      } else if (userIDData.value) {
         tempUser.value = { ...userIDData.value as User }
         console.log(`  tempUser = (${JSON.stringify(userIDData.value)})`)
      }
      isPending.value = false
   }

   async function fetchUserFullname(id: number) {
      console.log(`\n fetchUserFullname(${id})`)
      let fullname = ''
      userID.value = id

      await execUserID()

      if (userIDError.value) {
         showError(userIDError.value as ErrorT)
         isPending.value = false
         return false
      } else if (userIDData.value) {
         console.log(`  fullname = (${JSON.stringify(userIDData.value)}, ${userIDData.value.firstName} ${userIDData.value.lastName})`)
         fullname = `${userIDData.value.firstName} ${userIDData.value.lastName}`

         isPending.value = false
         return fullname
      }
   }

   function setUserTitle(id: number) {
      // const indexOfRole = Object.values(roles).indexOf(id as unknown as UserRole)
      // const key = Object.keys(UserRole)[indexOfRole]
      // const titleString = key.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
      // console.log(`setUserTitle(${id}): key = ${key}`)

      const userRole = roles.find((item: Role) => item.value === id)
      if (userRole) title.value = userRole.title
   }

   const { data: createData, error: createError, execute: execCreate } = useFetch(`${config.public.API_URL}/api/users`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: newUser,
      immediate: false,
      watch: false,
   })

   async function createUser() {
      console.log(`createUser()`)
      isPending.value = true

      await execCreate()

      if (createError.value) {
         if (createError.value.data.message === 'Duplicate Entry') {
            setSnackbar({
               type: `error`,
               text: `User/Email Already Exists`,
            })
         } else {
            showError(createError.value as ErrorT)
         }
         isPending.value = false

         return false
      } else if (createData.value) {
         cacheBuster.value += 1
         setSnackbar({
            type: `success`,
            text: `New User successfully created!`,
         })
         // console.log(`User Created! - ${JSON.stringify(createData.value)}`)
         // navigateTo({ path: '/users' })
         isPending.value = false

         return createData.value.message
      }
   }

   const { data: updateData, error: updateError, execute: execUpdate } = useFetch(() => { return userURL.value }, {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: tempUser,
      immediate: false,
      watch: false,
   })

   async function updateUser() {
      isPending.value = true
      // console.log(`\nupdateUser(): form = ${JSON.stringify(user)}`)

      if (tempUser.value) {
         userID.value = tempUser.value.id

         await execUpdate()

         if (updateError.value) {
            if (updateError.value.data.message === 'Duplicate Entry') {
               setSnackbar({
                  type: `error`,
                  text: `User/Email Already Exists`,
               })
            } else {
               showError(createError.value as ErrorT)
            }
            isPending.value = false

            return false
         } else if (updateData.value) {
            setSnackbar({
               type: `success`,
               text: `User updated successfully!`,
            })
            cacheBuster.value += 1
            fetchUsers()
            isPending.value = false

            return true
         }
      }
   }

   const deletedID = ref(0)
   const deletedURL = computed(() => `${config.public.API_URL}/api/user/${deletedID.value ? deletedID.value : 0}`)

   const { data: deletedData, error: deletedError, execute: execDeleted } = useFetch(() => { return deletedURL.value }, {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })

   async function deleteUser(id: number) {
      console.log(`deleteUser(${id})`)

      deletedID.value = id

      await execDeleted()

      if (deletedError.value) {
         showError(deletedError.value as ErrorT)
      } else if (deletedData.value) {
         setSnackbar({
            type: `success`,
            text: 'User successfully deleted.',
         })
         console.log(`User deleted!`)
      }
      isPending.value = false
   }

   function $reset() {
      users.value = <Array<User>>[]
      user.value = <User>{} as User
      loginUsers.value = <Array<User>>[]
      tempUser.value = <User>{} as User
      newUser.value = <User>{} as User
      title.value = ''
      isValid.value = true
      isPending.value = false
      error.value = null
      total.value = 0
      cacheBuster.value = 0
   }

   return {
      users, user, loginUsers, tempUser, newUser, title, isValid, isPending, error, total, cacheBuster, parameters, getUserById, fullName, role, unreadUserNotifications, isAdmin, isCommittee, isDirector, isInstructor, params, fetchLoginUsers, fetchUsers, fetchUser, fetchUserById, fetchUserFullname, setUserTitle, createUser, updateUser, deleteUser, $reset
   }

},
   {
      persist: true,
   }
)

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
