import validate from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/pages/runtime/validate.js";
import section_45global from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/middleware/section.global.ts";
import manifest_45route_45rule from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  section_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/middleware/auth.ts")
}