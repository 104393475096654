import { usePageStore } from '~/stores/page'
import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware(async (to, from) => {
   const { pageName, rootPage } = storeToRefs(usePageStore())

   pageName.value = []
   pageName.value = ([to.name])

   const params = to.fullPath.startsWith('/') ? to.fullPath.substring(1).split('/') : to.fullPath.split('/')

   rootPage.value = params[0]
   // console.log(`\nto = ${JSON.stringify(to)}\npageName = ${pageName.value} \nrootPage = ${rootPage.value}`)
})