export const useFilterStore = defineStore('filterStore', () => {
   const categoryFilters = ref<number[]>([])
   const chapterFilters = ref<number[]>([])
   const questionTagFilters = ref<number[]>([])
   const testTagFilters = ref<number[]>([])
   const questionTypeFilters = ref<number[]>([])
   const viewFilters = ref<number[]>([])
   const roleFilters = ref<number[]>([])

   function $reset() {
      categoryFilters.value = []
      chapterFilters.value = []
      questionTagFilters.value = []
      testTagFilters.value = []
      questionTypeFilters.value = []
      viewFilters.value = []
      roleFilters.value = []
   }

   return { categoryFilters, chapterFilters, questionTagFilters, testTagFilters, questionTypeFilters, viewFilters, roleFilters, $reset }
},
   {
      persist: true,
   }
)

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useFilterStore, import.meta.hot))
}
