import type { ErrorT } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'

export function useAPI() {
   const { setSnackbar } = useSnackbarStore()
   const { logout } = useAuthStore()
   const { isRedirect, reloadPage } = storeToRefs(useAuthStore())    
      
   async function showError(error: ErrorT): Promise<void> {
      console.log(`ERROR! - ${error.statusMessage} - ${error.statusCode}`)
      if (error && error.statusCode === 403) {
         isRedirect.value = true
         reloadPage.value = true
         await logout()
      } else {
         setSnackbar({
            type: `error`,
            text: `Server communication error. Please try again.`,
         })
      }
   }

   return {
      showError
   }
}
