import type { Snackbar } from '~/types'

export const useSnackbarStore = defineStore('snackbarStore', () => {
   const snackbar = ref({} as Snackbar)
   const snackbarShow = ref(false)
   const snackbarTimeout = ref(3000)

   function setSnackbar(temp: Snackbar) {
      console.log(`setSnackbar(${JSON.stringify(temp)})`)

      snackbarShow.value = true
      snackbar.value.type = temp.type || 'success'
      snackbar.value.text = temp.text || ''
      snackbar.value.timeout = temp.timeout || snackbarTimeout

      switch (snackbar.value.type) {
         case 'success':
            snackbar.value.icon = 'mdi-check-circle'
            snackbar.value.color = 'success'
            break
         case 'info':
            snackbar.value.icon = 'mdi-information'
            snackbar.value.color = 'info'
            break
         case 'warning':
            snackbar.value.icon = 'mdi-alert-circle'
            snackbar.value.color = 'warning'
            break
         case 'error':
            snackbar.value.icon = 'mdi-close-circle'
            snackbar.value.color = 'error'
            break

         default:
            snackbar.value.icon = 'mdi-check-circle'
            snackbar.value.color = 'success'
            break
      }
   }

   function $reset() {
      snackbar.value = {} as Snackbar
      snackbarShow.value = false
      snackbarTimeout.value = 3000
   }

   return {
      snackbar, snackbarShow, snackbarTimeout, setSnackbar, $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useSnackbarStore, import.meta.hot))
}
