import { default as index5r98BKE2fgMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/account/index.vue?macro=true";
import { default as index6g8jG0awPYMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/categories/index.vue?macro=true";
import { default as indexq5ToY7k43KMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/chapters/index.vue?macro=true";
import { default as indexCvSyQVz5HVMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/cleanup/index.vue?macro=true";
import { default as indexGNSCdFfpOTMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/index.vue?macro=true";
import { default as indexBfwhpCmMjIMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/login/index.vue?macro=true";
import { default as indexKeikRE2FP3Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/[id]/index.vue?macro=true";
import { default as indexjMd4MYa684Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/index.vue?macro=true";
import { default as deletedD0APv7l9O5Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/deleted.vue?macro=true";
import { default as indexUzL8qZagQmMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/index.vue?macro=true";
import { default as duplicateredDZZT2HhMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/duplicate.vue?macro=true";
import { default as editBK5DeiGLZXMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/edit.vue?macro=true";
import { default as newHMWnIRgJMPMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/new.vue?macro=true";
import { default as indexR60HjxXJi7Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/index.vue?macro=true";
import { default as indexC4hCkVfnkAMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/narratives/index.vue?macro=true";
import { default as indexxlhabbWVMjMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/index.vue?macro=true";
import { default as newIoWSH2qN9rMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/new.vue?macro=true";
import { default as indexZvMGpWFcLSMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/search/index.vue?macro=true";
import { default as editK7l84Wv4RqMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/edit.vue?macro=true";
import { default as indexPXtbpJXzQWMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/index.vue?macro=true";
import { default as indexUulqiEvOStMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/index.vue?macro=true";
import { default as newvLJ25n7cNKMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/new.vue?macro=true";
import { default as indexiGJZQX1b4zMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reset/index.vue?macro=true";
import { default as indexQGCYjjTXXFMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/support/index.vue?macro=true";
import { default as indexveeSUCcU10Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tags/index.vue?macro=true";
import { default as indexrkqxHtS4loMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/index.vue?macro=true";
import { default as duplicatec1FtBlCr2yMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/duplicate.vue?macro=true";
import { default as editeZFWUu1b00Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/edit.vue?macro=true";
import { default as newb6IFMYOgR8Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/new.vue?macro=true";
import { default as selectIglFtJXiNNMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/select.vue?macro=true";
import { default as indexi1v14xIAWMMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/index.vue?macro=true";
import { default as indexHfWtcR38V1Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/index.vue?macro=true";
import { default as newoSkzOM2FT8Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/new.vue?macro=true";
import { default as editiEPGop58WvMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/edit.vue?macro=true";
import { default as indexxpSgbE5naLMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/index.vue?macro=true";
import { default as indexzYVgy6LEmHMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/index.vue?macro=true";
import { default as newpwBoSuAkSVMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/new.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: index5r98BKE2fgMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    meta: index6g8jG0awPYMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "chapters",
    path: "/chapters",
    meta: indexq5ToY7k43KMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/chapters/index.vue").then(m => m.default || m)
  },
  {
    name: "cleanup",
    path: "/cleanup",
    meta: indexCvSyQVz5HVMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/cleanup/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexGNSCdFfpOTMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexBfwhpCmMjIMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications-id",
    path: "/notifications/:id()",
    meta: indexKeikRE2FP3Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexjMd4MYa684Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id-deleted",
    path: "/question-banks/:id()/deleted",
    meta: deletedD0APv7l9O5Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/deleted.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id-edit",
    path: "/question-banks/:id()/edit",
    meta: indexUzL8qZagQmMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id-edit-qn-qnid-duplicate",
    path: "/question-banks/:id()/edit/qn/:qnid()/duplicate",
    meta: duplicateredDZZT2HhMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id-edit-qn-qnid-edit",
    path: "/question-banks/:id()/edit/qn/:qnid()/edit",
    meta: editBK5DeiGLZXMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id-edit-qn-new",
    path: "/question-banks/:id()/edit/qn/new",
    meta: newHMWnIRgJMPMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/new.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id",
    path: "/question-banks/:id()",
    meta: indexR60HjxXJi7Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-id-narratives",
    path: "/question-banks/:id()/narratives",
    meta: indexC4hCkVfnkAMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/narratives/index.vue").then(m => m.default || m)
  },
  {
    name: "question-banks",
    path: "/question-banks",
    meta: indexxlhabbWVMjMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/index.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-new",
    path: "/question-banks/new",
    meta: newIoWSH2qN9rMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/new.vue").then(m => m.default || m)
  },
  {
    name: "question-banks-search",
    path: "/question-banks/search",
    meta: indexZvMGpWFcLSMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/search/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-id-edit",
    path: "/reports/:id()/edit",
    meta: editK7l84Wv4RqMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "reports-id",
    path: "/reports/:id()",
    meta: indexPXtbpJXzQWMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexUulqiEvOStMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-new",
    path: "/reports/new",
    meta: newvLJ25n7cNKMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/new.vue").then(m => m.default || m)
  },
  {
    name: "reset",
    path: "/reset",
    meta: indexiGJZQX1b4zMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexQGCYjjTXXFMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "tags",
    path: "/tags",
    meta: indexveeSUCcU10Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tags/index.vue").then(m => m.default || m)
  },
  {
    name: "tests-id-edit",
    path: "/tests/:id()/edit",
    meta: indexrkqxHtS4loMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "tests-id-edit-qn-qnid-duplicate",
    path: "/tests/:id()/edit/qn/:qnid()/duplicate",
    meta: duplicatec1FtBlCr2yMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: "tests-id-edit-qn-qnid-edit",
    path: "/tests/:id()/edit/qn/:qnid()/edit",
    meta: editeZFWUu1b00Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "tests-id-edit-qn-new",
    path: "/tests/:id()/edit/qn/new",
    meta: newb6IFMYOgR8Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/new.vue").then(m => m.default || m)
  },
  {
    name: "tests-id-edit-select",
    path: "/tests/:id()/edit/select",
    meta: selectIglFtJXiNNMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/select.vue").then(m => m.default || m)
  },
  {
    name: "tests-id",
    path: "/tests/:id()",
    meta: indexi1v14xIAWMMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "tests",
    path: "/tests",
    meta: indexHfWtcR38V1Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: "tests-new",
    path: "/tests/new",
    meta: newoSkzOM2FT8Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/new.vue").then(m => m.default || m)
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editiEPGop58WvMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexxpSgbE5naLMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexzYVgy6LEmHMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "users-new",
    path: "/users/new",
    meta: newpwBoSuAkSVMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/new.vue").then(m => m.default || m)
  }
]